import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, MenuItem, FormControl, InputLabel, Select, TextField, Snackbar } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { authAxios, serverConn } from '../../NetworkRequest';
import { useEffect } from 'react';


const CreateReservationDialog = ({
  open, 
  setOpen, 
})=>{
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [type, setType] = useState('')
    const [duration, setDuration] = useState('')
    const [durations, setDurations] = useState([])
    const [date, setDate] = useState(null)
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState('')
    const [success, setSuccess] = useState(false)
    const [snackOpen, setSnackOpen] = useState(false)
    const [snackError, setSnackError] = useState(false)



    const handleClose = () => {
      setOpen(false);
    };
    useEffect(()=>{
        //Get duratons, available dates
        async function getDurations(){
          try{
              const result = await serverConn.get('/reservation/durations')
              setDurations(result.data.durations)
          }catch(e){
              console.log(e)
          }
      }
  
      getDurations()
      },[])

    const onSave = async()=>{
      try{
        setErr('')
        if(!type || !name || !phone || !date || !duration)
          return
        if(name.trim().length < 3)
          return setErr('من فضلك ادخل الإسم')
        if(phone.trim().length !== 11)
          return setErr('من فضلك ادخل رقم الهاتف')  

        setLoading(true)
        let results = await authAxios.post('/reservation', {
          name,
          mobile: phone,
          visitType: type,
          date,
          durationId: duration
        })
  
        if(results.status === 201){
          setName('')
          setPhone('')
          setDate(null)
          setType('')
          setDuration('')
          setSnackOpen(true)
          setOpen(false);
        }
      }catch(e){
        setSnackError(true)
        console.log(e)
      }
    }

    function disabledDates(s) {
        let date = new Date(s)
        const i = durations.findIndex(el=>el.id === duration)
        return !durations[i].days[date.getDay()]
    }
    const onSelectDuration = ({target})=>{
        setDuration(target.value)
      }
  
      const onChangeDate = (value)=>{
        if(duration){
          setDate(dayjs(new Date(value)))
        }
        
      }
    const onChangeName = ({target})=>{
        setErr('')
        setName(target.value)
    }
    const onChangeMobile = ({target})=>{
        setErr('')
        setPhone(target.value)
    }

    return(
    <React.Fragment>
        <Snackbar
            color='success'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={snackOpen}
            onClose={()=>setSnackOpen(false)}
            message="تم انشاء الحجز"
            key={'bottomcenter'}
            autoHideDuration={2000}
        />
        <Snackbar
            color='error'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={snackError}
            ContentProps={{
            sx: {
              background: "red"
            }}}
            onClose={()=>setSnackError(false)}
            message="عفواً حدث خطأ ما يرجي المحاولة مرة أخرى"
            key={'bottomcenter'}
            autoHideDuration={6000}
        />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{textAlign: 'right'}}>انشاء حجز جديد</DialogTitle>
        <DialogContent>
        <TextField
                value={name}
                onChange={onChangeName}
                label='الإسم'
                variant='filled'
                sx={{marginBottom: 2}}
                fullWidth
            />
            <TextField 
                value={phone}
                onChange={onChangeMobile}
                sx={{marginBottom: 2}}
                label='رقم الهاتف'
                variant='filled'
                fullWidth
            />
          <FormControl fullWidth sx={{marginBottom: 2, textAlign: 'center' }}>
            <InputLabel id="select-duration">نوع الزيارة</InputLabel>
            <Select
              labelId="select-duration"
              value={type}
              label="durations"
              onChange={({target})=>setType(target.value)}
            >
              <MenuItem  value={'Revelation'}>{'كشف'}</MenuItem>
              <MenuItem  value={'Consultation'}>{'استشارة'}</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ textAlign: 'center'}} fullWidth>
            <InputLabel id="select-duration">الفترة</InputLabel>
                <Select
                labelId="select-duration"
                value={duration}
                label="فترة"
                onChange={onSelectDuration}
                >
                {durations && durations.map(d=><MenuItem key={d.id} value={d.id}>{d.title}</MenuItem>)}
                </Select>
          </FormControl>
          <DatePicker 
              shouldDisableDate={disabledDates} 
              disablePast 
              disabled={duration === ''}
              slotProps={{ textField: { style: { width: '100%' } } }} 
              sx={{marginTop: 2}}
              label="اختر تاريخ الحجز" 
              format="YYYY - MM - DD"
              value={date}
              onChange={onChangeDate}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>إلغاء</Button>
          <Button onClick={onSave}>انشاء</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
    )
}

export default CreateReservationDialog