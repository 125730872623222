import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Badge, Box, Tab, Tabs, Typography } from "@mui/material";
import { authAxios, socket } from "../../NetworkRequest";
import dayjs from "dayjs";
import ReservationRequest from "./ReservationRequest";
import CurrentDayReservations from "./CurrentDayReservations";
import { useSocketEvents } from "../../hooks/useSocketEvents";
import { useSelector } from "react-redux";
import moment from "moment";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import CreateReservationDialog from "../../components/Dialog/CreateReservationDialog";

const DashboardHome = (props) => {
  const [value, setValue] = useState(1);
  const [reservationRequests, setReservationRequests] = useState([]);
  const [latest, setLatest] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [dayRequests, setDayRequests] = useState(null);
  const [dayReservation, setDayReservation] = useState(dayjs(new Date()));
  const [durations, setDurations] = useState([]);
  const [filterDuration, setFilterDuration] = useState("");
  const socketState = useSelector((state) => state.socket);
  const [open, setOpen] = useState(false);
  const [confirm_msg, setConfirm_msg] = useState("");
  const [queue_msg, setQueue_msg] = useState("");
  const [come_msg, setCome_msg] = useState("");


    const groupedReservations = (reservations_arr)=>reservations_arr.reduce((acc, reservation) => {
      const { durationId } = reservation;
      if (!acc[durationId]) {
          acc[durationId] = [];
      }
      acc[durationId].push(reservation);
      return acc;
  }, {});



  useEffect(() => {
    async function getPending() {
      try {
        let results = await authAxios.get("/reservation/requests");
        setReservationRequests(results.data.reservations);
      } catch (e) {
        console.log(e);
      }
    }
    async function getByDay() {
      try {
        let q = `date=${dayReservation}`;
        if (filterDuration !== "") q += `&durationId=${filterDuration}`;
        let results = await authAxios.get(`/reservation/all?${q}`);
        let grouped = groupedReservations(results.data.reservations)
        setLatest(results.data.latest)
        let all_reservations = []

        Object.keys(grouped).forEach((key) => {
          let wait = grouped[key]
            .filter((r) => !r.done && !r.cancel && !r.didntCome)
            .map((r, i) => ({ ...r, queueNumber: i + 1 }));

          let rest = results.data.reservations.filter(
            (r) => (r.done || r.cancel || r.didntCome) && r.durationId === key
          );
          all_reservations = [...all_reservations, ...wait, ...rest]
        });

        setReservations(all_reservations);

      } catch (e) {
        console.log(e);
      }
    }
    async function getDurations() {
      try {
        const result = await authAxios.get("/reservation/durations");
        setDurations(result.data.durations);
      } catch (e) {
        console.log(e);
      }
    }
    async function getWhMessages() {
      try {
        let response = await authAxios.get("/admin/settings/whatsapp");
        setConfirm_msg(response.data.confirm_msg);
        setQueue_msg(response.data.queue_msg);
        setCome_msg(response.data.come_msg);
      } catch (e) {
        console.log(e);
      }
    }

    getWhMessages();
    getDurations();
    getPending();
    getByDay();

    socket.on(
      `${moment(new Date(dayReservation)).locale("en").format("YYYY-MM-DD")}`,
      (data) => {
        console.log("new socket");
        console.log(data);

        let grouped = groupedReservations(data)

        let all_reservations = []
        console.warn('got data', grouped)
        Object.keys(grouped).forEach((key) => {
          let wait = grouped[key]
            .filter((r) => !r.done && !r.cancel && !r.didntCome)
            .map((r, i) => ({ ...r, queueNumber: i + 1 }));

          let rest = data.filter(
            (r) => (r.done || r.cancel || r.didntCome) && r.durationId === key
          );
          all_reservations = [...all_reservations, ...wait, ...rest]
        });

        let s = all_reservations;
        if (filterDuration) {
          setReservations(
            s.filter((r) => r.durationId == filterDuration.toString())
          );
        } else setReservations(s);
        console.log(s);
        console.log("after set");
      }
    );

    return () => {
      socket.off(
        `${moment(new Date(dayReservation)).locale("en").format("YYYY-MM-DD")}`
      );
    };
  }, [filterDuration, socketState.isOnline, dayReservation]);

  const handleChangeSection = (event, newValue) => {
    setValue(newValue);
  };

  const events = [
    {
      name: "requests",
      handler(data) {
        setReservationRequests(data);
        console.log(data);
        setDayRequests(null);
      },
    },
    {
      name: "latest",
      handler(data) {
        setLatest(data);
        console.log('LATEST: ', data);
      },
    },
  ];
  useSocketEvents(events);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ar">
      <Layout>
        <CreateReservationDialog open={open} setOpen={setOpen} />

        <Tabs value={value} onChange={handleChangeSection} centered>
          <Tab
            label={
              <Typography style={{ marginTop: 9 }}> حجوزات اليوم</Typography>
            }
          />
          <Tab
            label={
              <Badge badgeContent={reservationRequests.length} color="error">
                <Typography style={{ marginTop: 9 }}> طلبات الحجز</Typography>
              </Badge>
            }
          />
        </Tabs>
        {value === 1 && (
          <ReservationRequest
            reservationRequests={reservationRequests}
            setReservationRequests={setReservationRequests}
            dayReservation={dayRequests}
            setDayReservation={setDayRequests}
            durations={durations}
            confirm_msg={confirm_msg}
            queue_msg={queue_msg}
            come_msg={come_msg}
          />
        )}
        {value === 0 && (
          <CurrentDayReservations
            dayReservation={dayReservation}
            setDayReservation={setDayReservation}
            reservations={reservations}
            setReservations={setReservations}
            durations={durations}
            filterDuration={filterDuration}
            setFilterDuration={setFilterDuration}
            confirm_msg={confirm_msg}
            queue_msg={queue_msg}
            come_msg={come_msg}
            latest={latest}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
            position: "fixed",
            bottom: 20,
            right: 20,
          }}
        >
          <Fab onClick={() => setOpen(true)} color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        </Box>
      </Layout>
    </LocalizationProvider>
  );
};

export default DashboardHome;
